.react-tabs__tab--selected {
  color: $color-secondary !important;
  background-color: $color-bg-secondary !important;
  border: 1px solid $color-border-primary !important;
  border-bottom-width: 0 !important;
}

.react-tabs__tab {
  padding: 10px 15px 10px 15px !important;
  height: 42px !important;
  user-select: none;
  color: $color-text-primary;
  border-radius: $corner-base $corner-base 0 0;
  @extend .text-h3;
}

.react-tabs__tab--button {
  @extend .react-tabs__tab;
  height: 39px !important;
  padding: 0px 15px 0px 15px !important;
  float: right;
}

.react-tabs__tab--button:last-child {
  @extend .react-tabs__tab--button;
  padding-right: 0 !important;
}

.react-tabs__tab:focus:after {
  background: transparent !important;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
}

.react-tabs__tab-list {
  margin-top: 0.75rem !important;
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
  margin-bottom: -12px !important;
  margin-inside: 0 !important;
  border-bottom: 1px solid $color-border-primary !important;
  background-color: transparent;
}

.react-tabs__tab-panel {
}

.react-tabs__tab--disabled {
  color: $color-text-disable !important;
}

.order-document-tabs {
  background-color: white;
  border: 1px solid $color-gray-200;
  border-radius: 12px;
}
