/*------------------------------------
  Accessibility
------------------------------------*/

:focus,
a:focus,
button:focus {
  @if $enable-accessibility {
    outline: $outline;
  } @else {
    outline: 0;
  }
}

.form-control:focus {
  @if $enable-accessibility {
    box-shadow: $bs-input-btn-focus-box-shadow;
  } @else {
    box-shadow: $input-btn-focus-box-shadow-disabled;
  }
}

.btn {
  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    @if $enable-accessibility {
      box-shadow: $bs-input-btn-focus-box-shadow;
    } @else {
      box-shadow: $input-btn-focus-box-shadow-disabled;
    }
  }
}

.btn {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    &:focus {
      @if $enable-accessibility {
        box-shadow: $bs-input-btn-focus-box-shadow;
      } @else {
        box-shadow: $input-btn-focus-box-shadow-disabled;
      }
    }
  }
}

.custom-select:focus,
.page-link:focus {
  @if $enable-accessibility {
    box-shadow: $bs-input-btn-focus-box-shadow;
  } @else {
    box-shadow: $input-btn-focus-box-shadow-disabled;
  }
}

.custom-control-input {
  &:checked ~ .custom-control-label::before,
  &:focus ~ .custom-control-label::before,
  &:active ~ .custom-control-label::before {
    @if $enable-accessibility {
      box-shadow: $bs-input-btn-focus-box-shadow;
    } @else {
      box-shadow: $input-btn-focus-box-shadow-disabled;
    }
  }
}

.custom-file-input {
  &:focus ~ .custom-file-label {
    @if $enable-accessibility {
      box-shadow: $bs-input-btn-focus-box-shadow;
    } @else {
      box-shadow: $input-btn-focus-box-shadow-disabled;
    }
  }
}

.bootstrap-select .dropdown-toggle:focus {
  @if $enable-accessibility {
    outline: $outline;
  } @else {
    outline: 0 !important;
  }
}
