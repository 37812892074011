.alert {
  animation: fadeInFadeOutRight 15s cubic-bezier(0.34, 1.56, 0.64, 1) 0s 1
    normal forwards;
}

@keyframes fadeInFadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
    text-indent: 0;
    visibility: visible;
  }
  6%,
  90% {
    opacity: 1;
    transform: translateX(0px);
    text-indent: 0;
    visibility: visible;
  }
  99% {
    opacity: 0;
    transform: translateX(100px);
    text-indent: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
    text-indent: -9999px;
    visibility: hidden;
  }
}

.message-wrapper {
  padding-left: 30px;
  top: 63px;
  z-index: 2000;
}
