.nav-drawer {
  display: flex;
  flex: 0 0 auto;
  outline: 0;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  flex-direction: column;
  color: black;
  background-color: white;
  .nav-drawer-content {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
  }
}

.TrtExpress-navmenu {
  height: 56px;
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
  overscroll-behavior-y: contain;
}
.logo-TrtExpress-color {
  width: auto;
  height: 48px;
  font-size: 3rem;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  box-sizing: border-box;
  content: url(../img/trt-logo-light.svg);
  -webkit-font-smoothing: antialiased;
}

.logo-TrtExpress-tracking {
  width: auto;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  box-sizing: border-box;
  content: url(../img/logo-TrtExpress.png);
  -webkit-font-smoothing: antialiased;
}

@include media-breakpoint-down(md) {
  .logo-TrtExpress-tracking {
    height: 50%;
  }
  .tracking-logo-container {
    justify-content: end !important;
  }
}

@include media-breakpoint-down(sm) {
  .tracking-header-row {
    flex-direction: column !important;
  }
  .tracking-logo-container {
    order: -1 !important;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center !important;
  }
  .tracking-header-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .logo-TrtExpress-tracking {
    width: 95%;
  }
}

.nav-ripple {
  position: relative;
  transform: translate3d(0, 0, 0);
}
.nav-item {
  color: $color-action-tetriary;
  justify-content: center;
  text-decoration: none;
  overflow: visible;
  height: 100%;
  position: relative;
}

.nav-link.active {
  color: inherit;
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.nav-link .nav-link-text {
  text-align: left;
  letter-spacing: 0;
  white-space: nowrap;
}
.icon-root {
  color: black;
  width: 1em;
  height: 1em;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
.nav-icon-size-medium {
  font-size: 1.5rem;
}
.nav-link {
  height: 100%;
  font-weight: $font-weight-bolder;
  padding: 8px 16px 8px 16px;
  color: inherit;
  line-height: 1.2;
  display: inline-flex;
  font-size: $font-size-base * 0.875;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  font-family: $font-family-base;
  font-style: normal;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: visible;
  @extend .no-select;

  &:hover {
    color: inherit;
  }
}

.nav-group .nav-link {
  font-weight: $font-weight-normal;
}

.nav-background {
  height: 100%;
  min-height: 100vh;
}

.drawer {
  width: 100%;
  background-color: $color-primary;
  position: relative;
  z-index: 90;
  min-height: 54px;
  box-shadow: -3px -6px 20px rgba(165, 195, 230, 0.6);
}

.mud-drawer-content {
}

.menu-icon-wrapper {
  min-width: 20px;
  margin-right: 9px;
}

.form-group .menu-icon-wrapper {
  margin-right: 0;
}

.nav-group {
  left: 0;
  list-style-type: none;
  background-color: $color-primary;

  content-visibility: visible;
  .nav-item {
    height: auto;
  }
}

.menu-toggle {
  border: none;
  background: transparent;
  @extend .pointer;
  min-height: 34px;
}

.upload-cloud-icon {
  width: auto;
  height: 40px;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  box-sizing: border-box;
  content: url(../img/upload-cloud.svg);
  -webkit-font-smoothing: antialiased;
}

.edit-pen-icon {
  width: auto;
  height: 40px;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  box-sizing: border-box;
  content: url(../img/edit-pen-icon.svg);
  -webkit-font-smoothing: antialiased;
}

.search-icon {
  width: auto;
  height: 40px;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  box-sizing: border-box;
  content: url(../img/search-icon.svg);
  -webkit-font-smoothing: antialiased;
}
