.btn {
  height: 34px;
  padding: 8px 16px;
  @extend .text-button-primary;
}

.btn-lg {
  height: 40px;
  padding: 8px 16px;
  @extend .text-button-primary;
}

.btn-block {
  min-width: auto;
}

.btn-primary {
  color: $color-text-revers;
  background-color: $color-main-accent;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-secondary !important;
    color: $color-text-revers;
    border: 1px solid $color-secondary !important;
  }
  &:disabled {
    border-color: $color-bg-disabled;
    background-color: $color-secondary;
  }
}

.btn-secondary {
  color: $color-secondary;
  background-color: $color-bg-tetriary;
  border-color: $color-main-accent;
  &:hover,
  &:active,
  &:focus {
    color: $color-secondary !important;
    background-color: $color-bg-secondary !important;
    border: 1px solid $color-main-accent !important;
  }
  &:disabled {
    color: $color-border-primary;
    border-color: $color-bg-disabled;
    background-color: $color-bg-primary;
  }
}

.btn-success {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.btn-info {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.btn-warning {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.btn-danger {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.btn-outline-secondary {
  color: $color-gray-700;
  border: 1px solid $color-gray-300 !important;
  background-color: #ffffff !important;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    color: $color-gray-700 !important;
  }
  &:hover {
  }
  &:disabled {
    box-shadow: none;
  }
}

.btn-action-waiting {
  color: $color-text-revers;
  background-color: $color-action-waiting !important;
  &:hover,
  &:active,
  &:focus {
    color: $color-text-revers;
  }
}

.btn-action-secondary {
  color: $color-text-revers;
  background-color: $color-text-disable !important;
  &:hover,
  &:active,
  &:focus {
    color: $color-text-revers;
  }
}

.btn-action-primary {
  color: $color-primary-700;
  background-color: $color-primary-100;
  border-color: $color-primary-50;
  &:hover,
  &:active,
  &:focus {
    color: $color-primary;
  }
}

.btn-add-barcode {
  font-size: $h3-font-size !important;
  font-weight: $font-weight-medium !important;
}

.btn-facebook {
  color: #ffffff;
  background-color: $facebook;
  &:hover {
    background-color: darken($facebook, 5%) !important;
    color: #ffffff !important;
  }
}

.btn-twitter {
  color: #ffffff;
  background-color: $twitter;
  &:hover {
    background-color: darken($twitter, 5%) !important;
    color: #ffffff !important;
  }
}

.btn-google {
  color: #ffffff;
  background-color: $google;
  &:hover {
    background-color: darken($google, 5%) !important;
    color: #ffffff !important;
  }
}

.btn-cancel {
  background: #f2f4f6;
  color: #778ca2;
  border-color: transparent;
  border-radius: 4px;
  min-width: auto !important;
}

.btn-cancel:hover {
  background: #778ca2;
  color: #f2f4f6;
  border-color: transparent;
}

.btn-cancel:not(:disabled):not(.disabled):active,
.btn-cancel:not(:disabled):not(.disabled).active,
.show > .btn-cancel.dropdown-toggle {
  background: #778ca2;
  color: #f2f4f6;
  border-color: transparent;
  border-radius: 4px;
}

.btn-cancel:focus,
.btn-cancel.focus {
  color: #f2f4f6;
  background-color: #778ca2;
  border-color: transparent;
}

.btn-cancel::selection {
  color: #f2f4f6;
  background-color: #778ca2;
}

.btn-close {
  min-width: 20px !important;
  min-height: 20px !important;
  border: none;
  color: #000000;
}
.icon-close {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M7.7493 6.99699L13.3773 1.3635C13.4766 1.26372 13.5323 1.12865 13.5322 0.987892C13.5321 0.84713 13.4762 0.712142 13.3768 0.612495C13.1778 0.414495 12.8273 0.413495 12.6263 0.613495L6.9998 6.24699L1.3713 0.611995C1.1713 0.414495 0.820797 0.415495 0.621797 0.612995C0.572403 0.662192 0.533302 0.720734 0.506776 0.785204C0.480249 0.849674 0.466829 0.918782 0.467297 0.988495C0.467297 1.1305 0.522297 1.2635 0.621797 1.362L6.2498 6.9965L0.622297 12.6315C0.52299 12.7314 0.467377 12.8667 0.467658 13.0076C0.467939 13.1484 0.524092 13.2835 0.623797 13.383C0.720297 13.4785 0.856797 13.5335 0.997797 13.5335H1.0008C1.1423 13.533 1.2788 13.4775 1.3733 13.381L6.9998 7.7475L12.6283 13.3825C12.7278 13.4815 12.8608 13.5365 13.0018 13.5365C13.0715 13.5367 13.1406 13.5231 13.205 13.4965C13.2695 13.4699 13.3281 13.4309 13.3774 13.3816C13.4267 13.3323 13.4657 13.2737 13.4923 13.2092C13.5189 13.1448 13.5325 13.0757 13.5323 13.006C13.5323 12.8645 13.4773 12.731 13.3773 12.6325L7.7493 6.99699Z" fill="black"/> </svg>');
  background-repeat: no-repeat;
  object-fit: cover;
}

.btn-close:hover {
  color: #000000;
}
.btn-close:focus,
.btn-close.focus {
  color: #000000;
  border-color: transparent;
  box-shadow: transparent;
}
.btn-close:not(:disabled):not(.disabled):active,
.btn-close:not(:disabled):not(.disabled).active,
.show > .btn-close.dropdown-toggle {
  color: #000000;
  border-color: transparent;
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.buttonIcon {
  cursor: pointer;
}

.btn-dialog-save {
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  line-height: 24px;
  width: 100%;
}

.btn-dialog-cancel {
  background-color: #ffffff;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  line-height: 24px;
  color: $color-gray-700;
  width: 100%;
}

.btn-toggle {
  background-color: #ffffff;
  // height: 40px;
  // padding: 10px 16px;
  border-radius: 8px;
  // font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.btn-toggle-text {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: $color-gray-700;
  text-transform: capitalize;
}

.btn-make-default {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $default;
  border-color: $default;
  border-radius: 14px;
  border-width: 2px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  &:hover,
  &:focus {
    color: $default !important;
    background-color: rgba($default, 0.1) !important;
  }
}

.btn-toggle:hover {
  background-color: #eff8ff;
}

.hover-bg:hover {
  background-color: #f9fafb;
}
.selected-item-bg {
  background-color: #ebebeb;
}
.selected-item-bg:hover {
  background-color: #e6e5e5;
}

.dropdown-item .icon-selector {
  color: #d0d5dd;
}

.dropdown-item:hover .icon-selector {
  color: $color-text-lable;
}

.create-entity-select-btn {
  border-radius: 0 0 5px 5px;
}

.inline-grid-row-button {
  font-size: 0.775rem;
  line-height: normal;
}
