.app-navbar {
  background-color: var(--navbar-bg-color, #005cc7);
  border-bottom: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  padding: 0.2rem 1rem;
  .navbar-brand {
    font-size: 1.25rem;
    font-weight: 500;

    a {
      color: var(--navbar-brand-color, #fff);
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: var(--navbar-link-color, #fff);
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0.5rem 1rem;
        &.active {
          color: var(--navbar-link-active-color, #fff);
        }
      }
    }
  }
  .dropdown-item {
    color: var(--navbar-dropdown-link-color, #212529);
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.25rem 0.25rem;
    &.active {
      background-color: var(--navbar-dropdown-link-active-bg-color, #f8f9fa);
    }
    &:hover {
      background-color: var(--navbar-dropdown-link-hover-bg-color, #0a6ee0);
    }
  }
}

// Navbar dropdown
