.header-form {
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-bottom: 20px;
}

.form-border-text {
  text-align: center;
  width: 100% !important;
  border: 2px solid $color-border-primary;
  height: 3em;
  line-height: 2.7em;
  border-radius: $corner-md;
  font-weight: $font-weight-medium;
}

.cursor-pointer {
  cursor: pointer;
}

.small-form {
  width: 500px;
}

.input-label-primary {
  white-space: nowrap; /* Prevents the text from wrapping into a new line */
  overflow: hidden; /* Hides the text that overflows the element's box */
  text-overflow: ellipsis; /* Adds an ellipsis (...) when the text overflows */
  max-width: 100%; /* Ensures the width of the label does not exceed its parent's width */
  line-height: 16px; /* Without that some chars in the label are being cutted at the bottom */
}

.check-radio-label {
  line-height: 20px;
}

.form-control.PhoneInput {
  display: flex;
}
