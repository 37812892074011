/*------------------------------------
  Box
------------------------------------*/

.box {
  background: $color-bg-tetriary;
  border: 1px solid $color-gray-200;
  box-shadow: 0 0 3px 3px rgba(153, 163, 177, 0.15);
  border-radius: $corner-md;
  padding: 32px;
}
