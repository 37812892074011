/*------------------------------------
  Dropdown
------------------------------------*/

.dropdown .dropdown-main-menu {
  display: none;
}

.dropdown.show .dropdown-main-menu {
  display: initial;
}

.dropdown-main-menu {
  box-shadow: $dropdown-box-shadow;
  overflow-x: hidden;
  white-space: nowrap;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 98;
  float: left;
  min-width: 4.4375rem;
  padding: 0.25rem 0;
  margin: 0.625rem 0 0;
  font-size: 1rem;
  color: #fdfdfd;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
}

.dropdown-item {
  font-size: $dropdown-item-font-size;

  &:active {
    color: $dropdown-link-color;
    background-color: $dropdown-link-hover-bg;
  }

  &.active:not(:focus):not(:active) {
    color: $dropdown-link-active-color;
    background-color: $dropdown-link-active-bg;
  }

  &[href]:hover {
    &.dropdown-toggle::after {
      background: $dropdown-toggle-link-pseudo-bg;
    }
  }
}

// Header
.dropdown-header {
  text-transform: uppercase;
  letter-spacing: $dropdown-header-letter-spacing;
  font-size: $dropdown-header-font-size;
  font-weight: $dropdown-header-font-weight;
  padding-top: $dropdown-header-padding-y;
  padding-bottom: $dropdown-header-padding-y;
}

.dropdown-menu-grid {
  width: max-content;
  position: absolute;
  transform: translate3d(-55px, 14px, 0px);
  will-change: transform;
}

.dropdown-menu-grid-fixed {
  width: max-content;
  position: fixed;
  transform: translate3d(-55px, 14px, 0px);
  will-change: transform;
  z-index: 50;
}
