.select-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #e7eaf3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $white;
  pointer-events: none;
  padding: 10px 12px;
}

.select__control {
  border-color: $color-border-primary !important;
}

.select--is-disabled {
  .select__control {
    background-color: $gray-100;
  }
}
