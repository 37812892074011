/*------------------------------------
  Default Styles
------------------------------------*/

html {
  position: relative;
  min-height: 100%;
}

main {
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $headings-color;
}

figure {
  margin-bottom: 0;
}

sup {
  right: -0.5rem;
}

dl {
  margin-bottom: 0;
}

dt {
  color: $dt-color;
}

dd {
  margin-bottom: $dd-margin-y;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: $white;
  background-color: $primary;
}

::selection {
  color: $white;
  background-color: $primary;
}

.bg-primary ::-moz-selection {
  color: $primary;
  background-color: $white;
}

.bg-primary ::selection {
  color: $primary;
  background-color: $white;
}
