label {
  margin-bottom: 4px;
}

.invalid-feedback + .form-control {
  border-color: $danger;
}

.invalid-feedback {
  display: block;
}
[type='search'] {
  min-width: 200px;

  &.search-with-icon {
    box-shadow: $shadow-base;
    width: 100% !important;
    padding-left: 40px;
  }
}

input:not(:placeholder-shown) {
  border-color: $color-border-primary;
}

.shadow-base {
  box-shadow: $shadow-base !important;
}

.input-main {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.no-wrap-textarea {
  white-space: nowrap; /* nowrap keeps long strings on one line */
}

input[type='tel'] {
  border: 0;
  line-height: 1;
}

.password-input-eye {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.number-select-adornment {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  height: 100%;
}

.number-select-arrow {
  background-color: #ebebeb;
  transform: scaleY(0.9);
  &:hover {
    color: rgb(163, 163, 163) !important;
  }
  &:active {
    color: #ebebeb !important;
    background-color: rgb(163, 163, 163);
  }
}
