.bg-violet {
  background: rgba(129, 0, 255, 0.5);
}
.bg-dark-blue {
  background: rgba(0, 21, 255, 0.32);
}
.bg-brown {
  background: rgba(150, 75, 0, 0.5);
}
.bg-gray {
  background: rgba(0, 0, 0, 0.15);
}
.bg-pink {
  background: rgba(255, 0, 243, 0.33);
}
.bg-light-red {
  background: rgba(254, 77, 109, 0.11);
}
.bg-light-green {
  background: rgba(140, 207, 77, 0.59);
}
.bg-main-secondary {
  background: rgba(54, 133, 224, 1);
}
.color-bg-secondary {
  background-color: $color-bg-secondary;
}
.color-text-disable {
  color: $color-text-disable;
}
.color-text-primary {
  color: $color-text-primary;
}
.color-text-success {
  color: $color-text-success;
}
.color-text-negative {
  color: $color-text-negative;
}
.color-text-neutral {
  color: $color-text-neutral;
}
.color-main-accent {
  color: $color-main-accent;
}
.color-action-positive {
  color: $color-action-positive;
}
.color-text-placeholder {
  color: $color-text-placeholder;
}
.color-action-disable {
  color: $color-action-disable;
}
