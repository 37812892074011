.location-info {
  &.location-info-md {
    font-size: 16px;
  }
  &.location-info-lg {
    font-size: 18px;
    font-weight: $font-weight-bold;
  }
  &.location-info-sm {
    font-size: 14px;
  }
}
