body,
html,
#root,
#content {
  position: absolute;
  background-color: $color-bg-secondary;
  height: 100%;
  width: 100%;
}

iframe {
  pointer-events: none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

.pointer {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none;
}

.input-group-text.dropdown-toggle {
  padding-left: 5px;
}

.commodities-for-order-list {
  .mx-3 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.accounting-transaction-status {
  color: #fff;
  font-size: 16px;
  line-height: 1;
}

.document-preview-toolbar {
  border-top-left-radius: $corner-md;
  border-top-right-radius: $corner-md;
  background-color: #1c1c1c80;
  color: $color-text-revers;
  display: flex;
  justify-content: space-between;
  @extend .px-4;
  @extend .py-3;
}

.tab-items-text {
  font-weight: 600;
  font-size: 14px;
  color: #005c98;
}

.round-checkbox-label-text {
  font-weight: 600;
  line-height: 17px;
  font-size: 14px;
  color: $color-gray-700;
  margin-left: 5px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .card-columns {
    column-count: 4;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    column-count: 4;
  }
}

.documents-card-pictures {
  font-size: 36px;
  background-color: $color-primary-100;
  border-radius: 12px;
  font-weight: 500;
  color: #175cd3;
  width: 100%;
  text-align: center;
}

.card-documents {
  border: '1px solid #F0F4FA';
  border-radius: '12px';
}

.radius-0 {
  border-radius: 0 !important;
}

.selectable-card,
.selectable-card-selected {
  background: $white;
  border-radius: 8px;
  border: 1px solid #d8e6fb;
  box-shadow: 0px 0px 8px 0px #0f4a841a;
  &:hover {
    background-color: #d8e6fc;
  }
}
.selectable-card-selected {
  background-color: #3081ef;
  pointer-events: none;
  & * {
    color: $white !important;
  }
}

.border-text {
  text-align: center;
  width: 100% !important;
  border: 2px solid $color-border-primary;
  height: 3em;
  line-height: 2.7em;
  border-radius: $corner-md;
  font-weight: $font-weight-medium;
  max-width: 23%;
}

.grid-text-link {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #8d8d8d;
  }
}

.transaction-status-badge {
  width: 90px;
  position: absolute;
  right: 40px;
}
