.grid {
  box-shadow: $shadow-base;
  border-radius: $corner-md;
  .table {
    margin-bottom: 0;
    table-layout: auto;
    & tbody tr[class='']:hover {
      background-color: rgb(247, 247, 247);
    }
  }
  .grid-toolbar {
    display: flex;
    align-items: center;
    min-height: 54px;
    background-color: $color-table-header;
    border: 1px solid $color-border-secondary;
    border-radius: $corner-md $corner-md 0 0;
    border-bottom: none;
    &:empty {
      display: none !important;
    }
  }

  .grid-footer {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .py-4;
    @extend .px-8;
    min-height: 54px;
    background-color: $color-table-footer;
    border: 1px solid $color-border-secondary;
    border-radius: 0 0 $corner-md $corner-md;
    border-top: none;
    margin-bottom: 20px;
    &:empty {
      display: none !important;
    }
  }

  .nested-row td {
    background-color: $color-table-header;
    height: 30px;
  }
  .commodity-name {
    font-size: 16px;
    color: $color-main-accent !important;
  }

  &:not(.grid-toolbar) {
    .bg-white,
    table,
    thead {
      border-top-left-radius: $corner-md;
      border-top-right-radius: $corner-md;
    }
    th:first-of-type {
      border-top-left-radius: $corner-md;
    }
    th:last-of-type {
      border-top-right-radius: $corner-md;
    }
  }

  &:not(.grid-footer) {
    .bg-white,
    table {
      border-bottom-left-radius: $corner-md;
      border-bottom-right-radius: $corner-md;
    }
    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: $corner-md;
      }
      td:last-of-type {
        border-bottom-right-radius: $corner-md;
      }
    }
  }
}

th {
  @extend .text-h4;
  background-color: $color-table-header;
  color: $color-text-primary;
  white-space: nowrap;
  border: 1px solid $color-border-secondary;
  border-left: none;
  border-right: none;
  height: 44px;
  a {
    color: $color-text-primary;
    text-transform: capitalize;

    &:hover {
      color: $color-text-primary;
    }

    &.inactive-link {
      color: $color-text-disable !important;
    }
  }
}

tr {
  td,
  th {
    &:first-child {
      padding-left: 0.75rem; // You can adjust this value as needed
    }
  }
  td {
    @extend .text-body;
    color: $color-text-primary;
    background-color: $color-bg-tetriary;
    height: 42px;
  }
  th {
    a.link {
      overflow-wrap: normal;
      word-break: normal;
      display: flex;
    }
  }
}

.dropdown-dots-vertical {
  vertical-align: middle;
}

.grid-expand-chevron {
  color: $color-gray-500;
}

.row-gap {
  row-gap: 5px;
}

//.dropdown-columns {
//  svg {
//    margin-top: 2px;
//  }
//}
