/*------------------------------------
  Typography
------------------------------------*/

.text-h1 {
  font-family: $font-family-base;
  font-size: $h1-font-size;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-h2 {
  font-family: $font-family-base;
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-h3 {
  font-family: $font-family-base;
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;

  &.text-underlined {
    text-decoration: underline;
  }
}

.text-h4 {
  font-family: $font-family-base;
  font-size: $h4-font-size;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-h3-tracking-number {
  font-family: $font-family-base;
  font-size: $font-size-base * 1.125;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-gray-700;
}

.text-body {
  font-family: $font-family-base;
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-body-table {
  font-family: $font-family-base;
  font-size: $font-size-base * 0.825;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-table-footer {
  font-family: $font-family-base;
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-gray-600;
}

.text-button-primary {
  font-family: $font-family-base;
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-button-secondary {
  font-family: $font-family-base;
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-label {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-text-lable;
}
.text-sublabel {
  font-family: $font-family-base;
  font-size: $font-size-base * 1.125;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-gray-700;
}

.text-placeholder {
  font-family: $font-family-base;
  font-size: $font-size-base * 0.875;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-text-primary;
}

.text-selected-tab {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
}

.text-label-reverse {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  letter-spacing: $letter-spacing;
  color: $color-text-revers;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h2 {
  margin-bottom: 16px;
}
