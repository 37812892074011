/*------------------------------------
  Vertical Progress Bar
------------------------------------*/

.progress-vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  background-color: $progress-vertical-bg;
  width: $progress-vertical-width;
  height: $progress-vertical-height;
}

.bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.html {
  background-color: $color-main-accent;
  animation: html 3s;
}

@keyframes html {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
