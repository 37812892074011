/*------------------------------------
  Borders
------------------------------------*/

@each $color, $value in $theme-colors {
  .border-left-#{$color} {
    border-left-color: $value !important;
  }
}

$table-border: 1px solid $color-border-primary;
