.autocomplete-dropdown-container {
  position: absolute;
  width: 96%;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.2);
  z-index: 10000000;
  padding: 5px 0;
  background-color: #fff;
}
.suggestion-item,
.suggestion-item--active {
  padding: 5px 10px;
  z-index: 10000000;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}
.suggestion-item {
  background-color: #fff;
}
.suggestion-item--active {
  background-color: #e9e9e9;
}
