/*------------------------------------
  Position Spaces
------------------------------------*/

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}
