/*------------------------------------
  Tables
------------------------------------*/
@include media-breakpoint-up(lg) {
  .dropdown-menu {
    div {
      .grid {
        .bg-white {
          .table {
            th {
              word-break: unset;
            }
            tbody tr td {
              word-break: unset;
            }
          }
        }
      }
    }
  }

  .table {
    max-width: 100%;

    th {
      word-break: break-all;
    }

    tbody tr td {
      word-break: break-all;
    }
  }
}

.table {
  border-radius: $corner-base;
  border: 1px solid $color-border-secondary;
  @extend .px-3;

  thead {
    border: 1px solid $color-border-secondary;
  }
  th {
    font-weight: $table-th-font-weight;
    border-left: none;
    border-right: none;
  }

  tbody tr {
    border: 1px solid $color-border-secondary;

    td {
      &:first-child {
        padding: 0.15rem 0.325rem 0.15rem 0.75rem !important;
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid $color-border-secondary;
      border-left: none;
      border-right: none;
      vertical-align: $table-tbody-tr-td-vertical-align;
      padding: 0.15rem 0.325rem !important;
      @extend .text-body-table;
      .select__control {
        cursor: pointer;
        background-color: transparent;
        border: none;
        box-shadow: none;
        min-width: 120px;
        min-height: unset !important;
      }
      .select__single-value {
        @extend .text-body-table;
      }

      &:last-child {
        border-left: none;
      }
      &:nth-last-child(2) {
        border-right: none;
      }
    }
    td.order-grid-order-status {
    }
    .order-grid-origin {
      max-width: 250px;
      white-space: nowrap;
    }
    .order-grid-destination {
      max-width: 250px;
      white-space: nowrap;
    }
    .three-dots {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  thead th {
    vertical-align: $table-thead-td-vertical-align;
    font-size: $font-size-xs;
    text-transform: uppercase;
    font-weight: $table-thead-th-font-weight;
    border: 1px solid $color-border-secondary;
    white-space: unset;
    border-left: none;
    border-right: none;
  }

  tfoot {
    th,
    td {
      color: $table-tfoot-color;
      font-weight: $table-tfoot-font-weight;
      border-top: $table-border-width solid $table-border-color;
    }
  }

  .btn {
    white-space: nowrap;
  }
}
