/*------------------------------------
  Avatar
------------------------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: $avatar-width;
  height: $avatar-height;
  @include border-radius($avatar-img-border-radius);

  &:not(img) {
    background-color: $avatar-bg-color;
  }
}

.avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  @include border-radius($avatar-img-border-radius);
}

.avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: $avatar-font-size;
  font-weight: $avatar-font-weight;
  pointer-events: none;
  text-transform: uppercase;
  @include border-radius($avatar-img-border-radius);
}

.avatar-centered {
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.avatar-circle {
  @include border-radius($avatar-circle-border-radius);

  .avatar-initials,
  .avatar-img {
    @include border-radius($avatar-circle-border-radius);
  }
}

.avatar-border-lg {
  border: $avatar-border-lg-width $avatar-border-lg-type $avatar-border-lg-color;
}

// Border Dashed
.avatar-border-dashed {
  border: $avatar-border-dashed-width $avatar-border-dashed-type
    $avatar-border-dashed-color;

  .avatar-initials {
    color: $avatar-border-dashed-initials-color;
    background-color: $avatar-border-dashed-initials-bg-color;
    transition: $avatar-border-dashed-transition;
  }

  &:hover {
    .avatar-initials {
      color: $avatar-border-dashed-hover-initials-color;
    }
  }
}
