/*------------------------------------
  Pagination
------------------------------------*/

.pagination {
  margin-bottom: 0;
}

.page-link {
  cursor: pointer;
  text-align: center;
  min-width: $pagination-min-width;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: $corner-md !important;

  @extend .text-h4;

  > span {
    @extend .text-h3;
    display: flex;
    align-items: center;
  }
}

.page-item:not(.active) .page-link:hover {
  color: $pagination-hover-color;
}

.page-item {
  margin-left: $pagination-margin-x;
  border-radius: $corner-md !important;

  &:first-of-type,
  &:last-of-type {

    min-height: 40px;
  }
  &:first-of-type {
    margin-right: 0px;
  }
  &:last-of-type {
    margin-left: 0px;
  }
}

.pagination-span {
  @extend .text-nowrap;
  @extend .no-select;
  @extend .text-table-footer;
  color: $color-secondary;
}

.pagination-select {
  @extend .text-table-footer;
  border-color: $color-border-secondary !important;
  min-height: 36px;
}
