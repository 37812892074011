/*------------------------------------
  Opacity
------------------------------------*/

.opacity-xs {
  opacity: 0.2;
}

.opacity-sm {
  opacity: 0.4;
}

.opacity {
  opacity: 0.6;
}

.opacity-lg {
  opacity: 0.8;
}
