.custom-control-input {
  width: 16px;
  height: 16px;
}

.custom-control-label::before {
  width: 16px;
  height: 16px;
}

.custom-control-label::after {
  width: 16px;
  height: 16px;
}

$checkbox-offset: 0px;

.custom-checkbox {
  display: flex;
  align-items: center;
  .custom-control-label::before {
    margin-left: -$checkbox-offset;
    border: 1px solid #205a9c;
    border-radius: $corner-base;
    background-color: $color-bg-tetriary;
  }
  .custom-control-label::after {
    margin-left: -$checkbox-offset;
  }
}

.custom-checkbox .custom-control-label {
  margin-left: $checkbox-offset;
  line-height: 16px;
  color: $color-text-primary;
  @extend .text-body;
  @extend .no-select;
}
.custom-control.custom-checkbox {
  z-index: unset;
}

.checkbox-charge-complete,
.checkbox-unassigned-customer,
.checkbox-content-verify {
  border-radius: 50%;
  border: 1px solid gray;
  background-color: $color-bg-tetriary;
  &.checkbox-charge-complete-checked,
  &.checkbox-unassigned-customer-checked,
  &.checkbox-content-verify-checked {
    color: $color-text-revers;
    border-color: $color-action-positive;
    background-color: $color-action-positive;
  }
}

.checkbox-unassigned-customer-invalid {
  background-color: $color-bg-tetriary;
  border-color: $color-text-danger;
  color: $color-text-danger;
}

.grid-custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

.grid-custom-checkbox:checked {
  background-color: $color-main-accent;
  border-color: $color-main-accent;
}
