$toggle-height: 15px;
$toggle-width: 31px;
$toggle-offset: 8px;
$toggle-point-width: 10px;
$toggle-point-left: calc(
  (#{$toggle-height} - #{$toggle-point-width}) / 2 - 0.8px
);
$toggle-point-bottom: calc(
  (#{$toggle-height} - #{$toggle-point-width}) / 2 - 1px
);
$toggle-point-translate: calc(
  #{$toggle-width} - #{$toggle-point-left} - #{$toggle-point-left} - #{$toggle-point-width} -
    2px
);

.custom-toggle {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  .custom-toggle-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .custom-toggle-label {
    padding-left: calc(#{$toggle-width} + #{$toggle-offset});
    line-height: 16px;
    color: $color-text-primary;
    @extend .text-body;
    @extend .no-select;
    cursor: pointer;
  }

  .label-first {
    padding-left: 0;
    padding-right: $toggle-offset;
    margin-right: $toggle-offset;
  }

  .font-size-sm {
    @extend .text-button-secondary;
    margin-bottom: 3px;
  }

  // toggle off
  .custom-toggle-checkmark {
    position: absolute;
    background-color: $color-bg-primary;
    border: 1px solid $color-border-secondary;
    width: $toggle-width;
    height: $toggle-height;
    border-radius: 30px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
    top: 0px;
  }
  .custom-toggle-checkmark:before {
    position: absolute;
    content: '';
    height: $toggle-point-width;
    width: $toggle-point-width;
    left: $toggle-point-left;
    bottom: $toggle-point-bottom;
    background-color: $color-action-disable;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  // toggle on
  .custom-toggle-input:checked + .custom-toggle-checkmark {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
  .custom-toggle-input:checked + .custom-toggle-checkmark:before {
    background-color: $color-bg-primary;
    -webkit-transform: translateX($toggle-point-translate);
    -ms-transform: translateX($toggle-point-translate);
    transform: translateX($toggle-point-translate);
  }

  // toggle off disabled
  .custom-toggle-input:disabled + .custom-toggle-checkmark {
    background-color: $color-bg-primary;
    border-color: $color-bg-disabled;
  }

  // toggle on disabled
  .custom-toggle-input:checked
    + .custom-toggle-input:disabled
    + .custom-toggle-checkmark {
    background-color: $color-text-disable;
    border-color: $color-text-disable;
  }
}
