/*------------------------------------
  Links
------------------------------------*/

.link {
  font-size: $font-size-sm;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @extend .no-select;
}

.inactive-link {
  font-size: $font-size-sm;
  cursor: not-allowed;
  color: #97a4af !important;
}
