// Icon Menu
.nav-link:hover {
  .menu-icon {
    color: $primary;
  }
}
.menu-icon {
  color: $gray-500;
}

.js-navbar-vertical-aside-submenu {
  .nav-link {
    margin-bottom: 10px !important;
    margin-left: 22px !important;
    @include media-breakpoint-down(sm) {
      margin-left: 0 !important;
      padding-left: 0.25rem !important;
    }
  }
}

.js-navbar-vertical-aside-menu-link {
  padding-left: 20px !important;
}

.nav-link-toggle {
  &:hover,
  :active {
    background-color: $gray-200;
  }
}

.nav-link-toggle:active {
  border-color: transparent;
  background-color: transparent;
}

.show > .nav-link-toggle {
  background-color: $gray-200 !important;
  border-color: $color-primary !important;
}

.navbar-vertical-aside-has-menu {
  margin-bottom: 0;
}

.navbar-vertical-aside {
  width: 260px;
  border-right: none;
}

.navbar-vertical-aside-mini-mode-hidden-elements {
  flex: 1;
  text-transform: uppercase;
  color: $gray-500;
  padding: 10px 0;
}

.navbar-vertical:not([class*='container']) .navbar-nav.navbar-nav-lg .nav-link {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle::after {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.nav-sub {
  @include media-breakpoint-down(sm) {
    position: absolute;
    background-color: white;
    width: 150px;
    top: -10px;
    left: 90px;
  }
}

.navbar-vertical-content {
  overflow: visible;
  @include media-breakpoint-down(sm) {
    width: 90px;
  }
}

.navbar-vertical-content-logo {
  margin: 20px 30px;
  img {
    max-width: 170px;
  }
}

.navbar-vertical-content-logo-sm {
  margin: 20px 30px;
  img {
    max-height: 50px;
  }
}
