// Add to src/style/_nav.scss
.ai-chat-toggle {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

// Add these new classes
.main-content-wrapper {
  transition: margin-right 0.3s ease;
  width: 100%;

  &.chat-open {
    margin-right: 400px; // Same as chat panel width
    width: calc(100% - 400px);
  }
}

.ai-chat-container {
  position: fixed;
  right: -400px;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1999;

  &.show {
    right: 0;
  }

  // Ensure the AIChat component fills the container
  > div {
    height: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  pointer-events: none; // Allow clicking through the container

  &.chat-open {
    right: 400px; // Match the AI chat width
  }

  // Ensure modals themselves can receive clicks
  .modal {
    pointer-events: auto;
  }
}

.modal.chat-open {
  .modal-dialog {
    max-width: calc(100% - 400px - 2rem);
    margin-right: calc(400px + 1rem);
    transition: max-width 0.3s ease, margin-right 0.3s ease;
  }
}

.modal.chat-open {
  .modal-content {
    max-width: 100%;
  }
}
