.transportation-method-badge {
  @extend .text-h2;
  color: $color-main-accent;
  display: flex;
  align-items: center;

  .transportation-method-badge-img {
    margin-right: 10px;
  }
}
