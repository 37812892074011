/*------------------------------------
  Form Pill
------------------------------------*/

.custom-select-pill,
.form-control-pill {
  @include border-radius($input-pill-border-radius);
}

.input-group-pill {
  @include border-radius($input-pill-border-radius);

  > .custom-select,
  > .form-control {
    &:first-child {
      @include border-left-radius($input-pill-border-radius);
    }

    &:last-child {
      @include border-right-radius($input-pill-border-radius);
    }
  }

  > .input-group-prepend {
    > .input-group-text,
    > .btn {
      @include border-left-radius($input-pill-border-radius);
    }
  }

  > .input-group-append {
    > .input-group-text,
    > .btn {
      @include border-right-radius($input-pill-border-radius);
    }
  }

  &.input-group-merge {
    .form-control:not(:last-child) {
      @include border-right-radius($input-pill-border-radius);
    }

    .input-group-prepend {
      > .input-group-text,
      > .btn {
        @include border-left-radius($input-pill-border-radius);
      }
    }
  }

  &.input-group-merge {
    .form-control:not(:first-child) {
      @include border-left-radius($input-pill-border-radius);
    }

    .input-group-append {
      > .input-group-text,
      > .btn {
        @include border-right-radius($input-pill-border-radius);
      }
    }

    .custom-select {
      @include border-radius($input-pill-border-radius);
    }
  }
}
