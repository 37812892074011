// Bootstrap setup
@import '~bootstrap/scss/functions';
@import 'loaders.css/src/animations/ball-beat.scss';

// Font family
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Front setup
@import 'themes/dark';
@import 'themes/light';
@import 'themes/color';

@import 'user-variables';

@import 'front-dashboard/variables';

// Bootstrap core
@import '~bootstrap/scss/bootstrap';

@import 'front-dashboard/front-dashboard';

// Custom core
@import 'common';
@import 'user';
@import 'buttons';
@import 'input';
@import 'checkbox';
@import 'grid';
@import 'login-register';
@import 'top-bar';
@import 'form';
@import 'navigation';
@import 'dialog';
@import 'nav';
@import 'navbar';
@import 'tabs';
@import 'select';
@import 'quill';
@import 'date-picker';
@import 'message';
@import 'toggle';
@import 'place-autocomplete';
@import 'location-info';
@import 'barcode-scanner';
@import 'chat';
