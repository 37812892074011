/*------------------------------------
  Scrollbar
------------------------------------*/

.scrollbar {
  height: 160px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    visibility: hidden;
    border-radius: 8px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.horizontal-scrollbar {
  overflow-x: auto;
  border-radius: 8px;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: #ffffff;
    border-radius: 0px 0px 8px 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 8px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.horizontal-scrollbar td:last-child {
  position: sticky;
  right: 0;
  // right: -20px; TODO
  box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.2);
  overflow: unset;
}
