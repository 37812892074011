/*------------------------------------
  Form Add Field
------------------------------------*/

.input-group-add-field {
  position: relative;
  margin-top: $input-group-add-field-margin-y;
}

.input-group-add-field:hover .input-group-add-field-delete {
  opacity: 1;
}

.input-group-add-field-delete {
  position: absolute;
  top: $input-group-add-field-top-offset;
  right: 0;
  width: $input-group-add-field-delete-width;
  color: $input-group-add-field-delete-color;
  opacity: 0;
  margin-right: -$input-group-add-field-delete-width;
  padding-left: $input-group-add-field-delete-padding-x;

  &:hover {
    color: $input-group-add-field-delete-hover-color;
  }
}

// Medium Devices
@include media-breakpoint-down(lg) {
  .input-group-add-field-delete {
    opacity: 1;
  }
}
