// Front Default Color Variables
// =========================================================

$root-path: '../';

// Grays
$gray-100: #f9fafc;
$gray-200: #f8fafd;
$gray-300: #e7eaf3;
$gray-400: #bdc5d1;
$gray-500: #97a4af;
$gray-600: #8c98a4;
$gray-700: #677788;
$gray-800: #71869d;
$gray-900: #1e2022;

// Colors
$white: #fff;
$dark: #132144;
$blue: #4d7cfe;
$teal: #2ce5f6;
$cyan: #00c9db;
$yellow: #f5ca99;
$orange: #ffab2b;
$red: #fe4d97;
$green: #6dd230;
$facebook: #35599e;
$google: #ea4335;
$twitter: #00adf3;
$instagram: #3f729b;
$github: #24292e;
$default: #00bd75;

$success: $teal;
$info: $green;
$warning: $orange;
$danger: $red;

// Theme Colors

$color-gray-200: #eaecf0;
$color-gray-300: #d0d5dd;
$color-gray-500: #98a2b3;
$color-gray-600: #475467;
$color-gray-700: #344054;
$color-gray-900: #101828;

$color-primary-50: #e6f3fb;
$color-primary-100: #eff8ff;
$color-primary-700: #005c98;

$color-primary: #005cc7;
$color-secondary: #3685e0;
$color-main-accent: #066ffc;

$color-bg-primary: #f0f0f0;
$color-bg-secondary: #f4f9ff;
$color-bg-tetriary: #ffffff;
$color-bg-disabled: #e1e3e6;

$color-text-primary: #1c1c1c;
$color-text-secondary: #1c1c1c;
$color-text-placeholder: #c1c1c1;
$color-text-label: $color-text-secondary;
$color-text-revers: #ffffff;
$color-text-disable: #939da7;
$color-text-success: #12b76a;
$color-text-negative: #f04438;
$color-text-neutral: #f79009;

$color-action-primary: #2f81ef;
$color-action-secondary: #205a9c;
$color-action-tetriary: #fdfdfd;
$color-action-hover: #286ec6;
$color-action-disable: #e1e3e6;
$color-action-positive: #29a645;
$color-action-negative: #dc3545;
$color-action-waiting: #ff7d00;
$color-action-at-trt: #ffc700;

$color-table-header: #f9fafb;
$color-table-dispatched: #f9e5e7;
$color-table-loaded: #e4f3e7;
$color-table-waiting: #fde1c5;
$color-table-at-trt: #fdf5da;
$color-table-footer: #ffffff;

$color-text-danger: #cd1b0e;
$color-text-lable: #667085;

$color-border-primary: $color-gray-300;
$color-border-secondary: $color-gray-200;

$shadow-base: 0px 0px 3px 3px rgba(153, 163, 177, 0.15);

$corner-base: 4px;
$corner-md: $corner-base * 2;
$corner-lg: $corner-base * 3;
$corner-xl: $corner-base * 4;
