/*------------------------------------
  Card
------------------------------------*/

.card {
  box-shadow: $card-box-shadow;
  background-color: $color-bg-secondary;
  border: 1px solid $color-border-primary !important;
  border-top: none !important;
  border-radius: 0 0 $corner-base $corner-base;
}

.card[href]:hover {
  .card-title,
  .card-header-title {
    color: $card-title-hover-color;
  }
}

.card-title {
  display: block;
  color: $card-title-color;
  margin-bottom: 0;
}

.card-subtitle {
  display: block;
  color: $card-subtitle-color;
  font-weight: $card-subtitle-font-weight;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  margin-top: 0;
  margin-bottom: $card-subtitle-margin-y;
}

.card-text {
  display: block;
  color: $card-text-color;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: $card-header-padding-y;
  padding-bottom: $card-header-padding-y;
}

.card-header-title {
  margin-bottom: 0;
}

.card-footer {
  display: block;
  padding-top: $card-footer-padding-y;
  padding-bottom: $card-footer-padding-y;
}

.cards-container {
  padding: 32px 24px;
  border: 1px solid #eaecf0;
  background: white;
  border-radius: 12px;
}

.custom-card {
  padding: 0;
  margin-bottom: 16px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  background-color: white;
}

.custom-card-body {
  border-radius: 12px;
  background-color: white;
  padding: 24px;
  border-top: none;
}

.drag-and-drop-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed #b9c6d3;
  padding: 56px 24px;
  color: #475467;
}

.custom-cards-columns {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  row-gap: 16px;
  column-gap: 24px;
}

.drag-and-drop-card p {
  font-size: 12px;
}

.click-upload-text {
  font-weight: 600;
  font-size: 14px;
  color: $color-main-accent;
}

.picture-card-uploaded {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #b9c6d3;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 16px;
}

.picture-card-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.picture-card-img {
  max-width: 100%;
  object-fit: cover;
}

.no-found-card {
  display: flex;
  padding: 32px 24px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
}

.no-found-card h4 {
  line-height: 20px;
  color: #475467;
}

.no-found-card-span {
  font-weight: 600;
  color: #005cc7;
}

.default-card {
  background-color: $white;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 3px rgba(153, 163, 177, 0.15) !important;
}
