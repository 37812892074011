//
// Content Centered
//

// mixing for centering contents
@mixin content-centered($hs-horizontal: true, $hs-vertical: true) {
  position: absolute;
  @if ($hs-horizontal and $hs-vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($hs-horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($hs-vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
