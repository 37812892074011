.status-badge {
  border-radius: $corner-xl;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  word-break: break-word;

  .status-badge-dot {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.status-badge-unloading,
.status-badge-loading,
.status-badge-loaded,
.status-badge-in-yard,
.status-badge-inyard,
.status-badge-pending {
  color: #175cd3;
  background-color: #eff8ff;
  .status-badge-dot {
    background-color: #2e90fa;
  }
}
.status-badge-in-progress,
.status-badge-inprogress,
.status-badge-onroute,
.status-badge-on-route,
.status-badge-inquote,
.status-badge-in-quote,
.status-badge-onhand,
.status-badge-on-hand,
.status-badge-intransit,
.status-badge-in-transit,
.status-badge-xlsx,
.status-badge-onpacking,
.status-badge-on-packing,
.status-badge-inservice,
.status-badge-in-service {
  color: #b54708;
  background-color: #fffaeb;
  .status-badge-dot {
    background-color: #f79009;
  }
}

.status-badge-active,
.status-badge-open,
.status-badge-posted,
.status-badge-dispatched,
.status-badge-covered,
.status-badge-delivered,
.status-badge-completed,
.status-badge-pdf,
.status-badge-invoiced,
.status-badge-picking,
.status-badge-picked {
  color: #027a48;
  background-color: #ecfdf3;
  .status-badge-dot {
    background-color: #12b76a;
  }
}

.status-badge-inactive,
.status-badge-damaged,
.status-badge-not-found,
.status-badge-refused,
.status-badge-notfound,
.status-badge-cancelled {
  color: #b42318;
  background-color: #fef3f2;
  .status-badge-dot {
    background-color: #f04438;
  }
}

.status-badge-jpg {
  color: #677788;
  background-color: #f8fafd;
  .status-badge-dot {
    background-color: $color-text-lable;
  }
}
