// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//

// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: 1rem;

$font-family-base: 'Inter', sans-serif;
$font-style-base: normal;

//buttons

$btn-padding-x: 20px;
$btn-padding-y: 11px;
$btn-font-size: 14px;

$nav-tabs-link-active-border-width: 2px;
$nav-tabs-link-hover-border-color: $color-primary;
$nav-tabs-link-active-border-color: $color-primary;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;
$modal-dialog-margin-y-md-up: 2.5rem !default;
$modal-dialog-margin-y-lg-up: 4rem !default;
$modal-dialog-margin-y-xl-up: 5rem !default;

// Table
$table-tbody-tr-td-vertical-align: middle;
$table-thead-td-vertical-align: middle;
