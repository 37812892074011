.default-clickable-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border-color: $default;
  border-radius: 14px;
  border-width: 2px;
  height: 24px;
  background-color: $default;
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
  &:hover,
  &:focus {
    color: $white !important;
    background-color: rgba($default, 0.5) !important;
    cursor: pointer;
  }
}

.default-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $default;
  border-radius: 14px;
  border-width: 2px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
}
