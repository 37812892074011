.login-register-header {
  margin-bottom: 50px;
}

//.react-tabs__tab-panel {
//  .card {
//    .card-body.login-register-card-body {
//      padding: 12px 12px 12px 12px;
//    }
//  }
//}
.modal-body {
  .card {
    border: none;
    box-shadow: none;

    .card-body.login-register-card-body {
      padding: 25px;
    }
  }
}
.card-body.login-register-card-body {
  padding: 25px;
}

.messages-wrapper {
  max-width: 500px;
  width: 100%;
}

.btn-sm {
  .i-register {
    font-size: 18px;
  }
}
