.tracking-number-badge {
  border-radius: $corner-xl;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  word-break: break-word;
  background-color: #f2f4f7;
}
