/*------------------------------------
  Button Icon Sizes
------------------------------------*/

.btn-icon {
  &.btn-xs {
    font-size: $btn-icon-font-size-xs;
    width: $btn-icon-width-xs;
    height: $btn-icon-height-xs;
  }

  &.btn-sm {
    font-size: $btn-icon-font-size-sm;
    width: $btn-icon-width-sm;
    height: $btn-icon-height-sm;
  }

  &.btn-lg {
    font-size: $btn-icon-font-size-lg;
    width: $btn-icon-width-lg;
    height: $btn-icon-height-lg;
  }
}
