/*------------------------------------
  Select
------------------------------------*/

.select__single-value {
  font-family: $font-family-base;
  font-style: $font-style-base;
  font-weight: $input-font-weight;
  font-size: 0.825rem;
  color: $color-text-primary;
}

.select__placeholder {
  font-family: $font-family-base;
  font-style: $font-style-base;
  font-weight: $input-placeholder-font-weight;
  white-space: nowrap;
  color: $color-text-placeholder !important;
}
.select__multi-value {
  &:hover {
    //background-color: hsla(0, 0%, 90%, 0.486);
  }
}
.select__multi-value__label {
  font-weight: $input-font-weight;
  cursor: pointer;
}

.select__control:not(:has(.select__placeholder)) {
  //border-color: $color-border-secondary !important;
}

.select__control.select__control--is-focused {
  border-color: $color-secondary !important;
}

.select__control {
  font-size: 0.825rem;
  min-height: 2rem !important;
  box-shadow: none !important;

  border-radius: $corner-md !important;
}

.select__indicator {
  padding: 4px 8px 4px 0 !important;
}

.select__value-container {
  padding: 2px 10px !important;
}

.select__option {
  font-size: 0.825rem !important;
}
