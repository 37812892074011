/*------------------------------------
  Modal
------------------------------------*/
.modal {
  padding-right: 0 !important;
  overflow-y: auto;

  .modal-dialog {
    .modal-content {
      box-shadow: 0 0 0.2rem 0.2rem #77777788 !important;

      .modal-header {
        min-height: 42px;
        background-color: $color-bg-secondary;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px solid $color-border-primary;

        .modal-title {
          @extend .text-h2;
          cursor: text !important;
          color: $color-secondary;
        }
      }

      .modal-body {
        background-color: $color-bg-secondary;

        .order-edit-form {
          padding: 0 !important;
        }

        .react-tabs {
          .react-tabs__tab-list {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.charge-modal {
  .modal-dialog {
    width: 897px;
  }
}

.trackingEvent-modal {
  .modal-dialog {
    width: 700px;
  }
}

.commodity-modal {
  .modal-dialog {
    width: 1297px;
  }
}

.send-email-modal {
  .modal-dialog {
    width: 797px;
  }
}

.select-contact-modal {
  .modal-dialog {
    width: 400px;
  }
}

.bol-manager-modal,
.rate-manager-modal {
  .modal-dialog {
    width: 700px;
  }
}

.receive-carrier-pay-modal {
  .modal-dialog {
    max-width: 30%;
  }
}

.receive-driver-pay-modal {
  .modal-dialog {
    max-width: 30%;
  }
}

.generate-invoice-modal {
  .modal-dialog {
    max-width: 30%;
  }
}

.confirmation-dialog-modal {
  .modal-dialog {
    max-width: 30%;
  }
}

.delete-modal,
.cancel-order-modal,
.alert-modal {
  background-color: rgba(52, 64, 84, 0.2);

  .modal-dialog {
    justify-content: center;
    display: flex !important;

    .modal-content {
      width: 450px;
    }
  }
}

.preview-image-modal {
  .modal-dialog {
    justify-content: center;
    display: flex !important;

    .modal-content {
      width: 1090px;
    }
  }
}

.modal-header {
  align-items: center;
  border-width: 0;
  padding-bottom: 0;

  .close {
    padding: $modal-close-padding-y $modal-close-padding-x !important;
    margin: 0 0 0 16px;
    color: $color-text-primary;
  }
}

.modal-footer {
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modal-footer-text:last-child {
  font-size: $modal-footer-text-font-size;
  margin-bottom: 0;
}

@include media-breakpoint-between(sm, md) {
  .modal-dialog {
    max-width: $modal-sm;
    margin: $modal-dialog-margin-y-sm-up auto;
  }
}

@include media-breakpoint-between(md, lg) {
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-md-up auto;
  }
}

@include media-breakpoint-between(lg, xl) {
  .modal-dialog {
    max-width: $modal-lg;
    margin: $modal-dialog-margin-y-lg-up auto;
  }
}

@include media-breakpoint-up(xl) {
  .modal-dialog {
    max-width: $modal-xl;
    margin: $modal-dialog-margin-y-xl-up auto;
  }
}

.modal-order-documents {
  border-radius: 12px;

  .modal-dialog {
    width: 60%;

    .modal-content {
      .modal-header {
        display: none;
      }

      .modal-body {
        background-color: $color-bg-secondary;
        border-radius: 0.75rem;
      }
    }
  }
}

.modal-save-view {
  border-radius: 12px;
  background-color: rgba(52, 64, 84, 0.2);

  .modal-dialog {
    width: 30%;

    .modal-content {
      .modal-header {
        display: none;
      }

      .modal-body {
        background-color: $white;
        border-radius: 12px;
      }
    }
  }
}

.modal-confirm-existed-view {
  border-radius: 12px;
  background-color: rgba(52, 64, 84, 0.2);

  .modal-dialog {
    width: 25%;

    .modal-header {
      display: none;
    }

    .modal-content {
      .modal-body {
        background-color: $white;
        border-radius: 12px;
      }
    }
  }
}

.modal-add-attachments {
  border-radius: 12px;

  .modal-dialog {
    width: 560px;

    .modal-content {
      .modal-header {
        display: none;
      }

      .modal-body {
        background-color: $white;
        border-radius: 12px;
      }
    }
  }
}

.modal-edit-attachment {
  border-radius: 12px;

  .modal-dialog {
    width: 30%;

    .modal-content {
      .modal-header {
        display: none;
      }

      .modal-body {
        background-color: $white;
        border-radius: 12px;
      }
    }
  }
}

.modal-report-damaged {
  background-color: #344054b2;

  .modal-dialog {
    width: 100%;
    max-width: 600px;

    .modal-content {
      border-radius: 12px;

      .modal-header {
        display: none;
      }

      .modal-body {
        padding: 24px;
        background-color: $white;
        border-radius: 12px;
      }
    }
  }
}

.preview-dialog-modal {
  width: max-content;
}
