.modal-content {
  border-radius: 5px;
}

.modal-header {
  background-color: $color-bg-secondary;
  padding: 5px 25px;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.modal {
  z-index: 96 !important;
}

.modal-body {
  padding: 20px;
  background-color: $color-bg-tetriary;
}

.modal-filled-body {
  background-color: $color-bg-secondary;
}

.confirm-message {
  line-height: 20px;
  padding: 0 8px;
}
