/*------------------------------------
  Icon
------------------------------------*/

.icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: $icon-font-size;
  width: $icon-width;
  height: $icon-height;
  @include border-radius($icon-border-radius);
}

.icon-circle {
  @include border-radius($icon-circle-border-radius);
}

.icon-centered {
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.icon-edit-entity {
  font-weight: 400 !important;
  color: #778ca2;
  background-color: transparent;
  cursor: pointer;
}

.icon-filter {
  top: '50%';
  left: '10px';
  transform: 'translateY(-50%)';
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.circle-outer {
  background-color: #eff8ff;
  width: 40px;
  height: 40px;
}

.circle-inner {
  background-color: #dff1ff;
  width: 30px;
  height: 30px;
}

.icon-badge {
  display: flex;
  padding: 6px;
  border-radius: 50%;
  background: #e1e5ee;
  border: 6px solid #f4f7fa;
}
