/*------------------------------------
  Custom Select
------------------------------------*/

.custom-select,
.custom-control-label {
  cursor: pointer;
}
textarea.form-control,
.form-control {
  font-weight: $input-font-weight;
  min-height: 2rem;
  height: auto;
  font-size: 0.825rem;
}
.form-control {
  padding: 2px 10px;
}
textarea.form-control {
  line-height: 1.3rem !important;
  padding: 8px 10px;
}
.form-control {
  .custom-control-label {
    width: 100%;
  }
}

.custom-select-lg {
  @include border-radius($btn-border-radius-lg);
}

.custom-select-sm {
  @include border-radius($btn-border-radius-sm);
}

// Radio Reverse
.custom-radio-reverse {
  width: 100%;
  padding-left: 0;
  padding-right: $custom-control-gutter + $custom-control-indicator-size;

  .custom-control-label {
    width: 100%;
  }

  .custom-control-label::before {
    left: auto;
    right: -($custom-control-gutter + $custom-control-indicator-size);
  }

  .custom-control-label::after {
    left: auto;
    right: -($custom-control-gutter + $custom-control-indicator-size);
  }
}
