@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker__input-container input {
  cursor: pointer;
}

.react-datepicker__triangle {
  transform: translate(125px, 0px) !important;
}

.react-datepicker__day--today {
  border: 1px solid gray;
  border-radius: 3px;
}

.calendar-icon {
  right: 15px;
}

.label-datepicker {
  cursor: pointer;
  margin: 0;
}
