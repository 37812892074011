.top-bar {
  background-color: white;
  .dropdown-menu {
    left: -10px !important;
  }
}

.top-bar-header-name {
  @extend .text-h1;
}

.top-bar-path {
  display: flex;
  align-items: center;

  @extend .text-h4;

  font-size: $font-size-sm;
  color: $color-gray-300;
  span {
    color: $color-gray-600;
    padding: 4px 8px;
    border-radius: 6px;

    &:last-child {
      font-weight: $font-weight-bold;
      color: $color-gray-700;
      background-color: $color-bg-secondary;
    }
  }
}

.i-top-bar {
  font-size: 20px;
  cursor: pointer;
}
.i-top-bar-blue {
  font-size: 20px;
  cursor: pointer;
  color: $blue;
}

.i-top-bar-bell {
  position: relative;
  .round {
    position: absolute;
    right: 0;
    background-color: $danger;
    width: 8px;
    height: 8px;
    border: 1px #ffffff solid;
    border-radius: 50%;
  }
}

.top-bar-img-user {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid $color-border-secondary;
}

.top-bar-search {
  input {
    border: none;
    padding-top: 4px;
  }
}

.organization-select {
  border-color: $color-border-secondary;
}

@include media-breakpoint-down(sm) {
  .top-bar-search {
    input {
      display: none;
    }
  }
}
