/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @if $color == 'secondary' {
      @include button-ghost($value, rgba($primary, 0.1));
    } @else {
      @include button-ghost($value, $value);
    }

    &.dropdown-toggle::after {
      background: str-replace(
          url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$value}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"),
          '#',
          '%23'
        )
        no-repeat
        right
        center/$dropdown-toggle-pseudo-width
        $dropdown-toggle-pseudo-height;
    }

    &.btn-no-focus {
      &[href] {
        &:focus,
        &.focus {
          color: $value;
          background-color: unset;

          &:hover {
            color: ($value);
            background-color: rgba($value, 0.1);
          }
        }
      }
    }
  }
}

// Ghost Secondary
.btn-ghost-secondary {
  &:hover,
  &:focus,
  &.focus,
  &:active {
    color: $primary;

    &.dropdown-toggle::after {
      background: str-replace(
          url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$primary}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"),
          '#',
          '%23'
        )
        no-repeat right center/$dropdown-toggle-pseudo-width
        $dropdown-toggle-pseudo-height;
    }
  }

  &.btn-no-focus {
    &[href] {
      &:focus,
      &.focus {
        &:hover {
          color: $primary;
          background-color: rgba($primary, 0.1);
        }
      }
    }
  }
}
